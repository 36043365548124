<script>
export default {
  name: 'SearchForm',
  template: '#search-form-template',
  data() {
    return {
      searchTerm: '',
      isLoading: false,
      isDropdownVisible: false,
      allValues: [],
      filteredValues: [],
      debounceTimer: null
    };
  },
  created() {
    axios.get('/search-autocomplete')
      .then(response => {
        this.allValues = response.data;
      })
      .catch(error => {
        console.error('Error fetching autocomplete data:', error);
      });
  },
  watch: {
    searchTerm(newVal) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.filterValues(newVal);
      }, 0); // Adjust debounce time as needed
    }
  },
  methods: {
    submitSearch() {
      if (this.searchTerm == '') {
        return;
      }

      // sets loading state
      this.isLoading = true;
      this.isDropdownVisible = false;

      // sends form after 1 sec
      setTimeout(() => {
        $('#search-form-id').submit();
      }, 50);
    },
    normalizeString(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    },
    filterValues(searchTerm) {
      const normalizedSearchTerm = this.normalizeString(searchTerm);
      this.filteredValues = this.allValues
        .filter(item => this.normalizeString(item).includes(normalizedSearchTerm))
        .sort((a, b) => {
          // Prioritize matches that start with the search term
          const normalizedA = this.normalizeString(a);
          const normalizedB = this.normalizeString(b);
          return normalizedA.indexOf(normalizedSearchTerm) - normalizedB.indexOf(normalizedSearchTerm);
        })
        .slice(0, 20);

      this.isDropdownVisible = (this.filteredValues.length > 0 && this.searchTerm.length > 0 && !this.isLoading);
    },
    selectValue(value) {
      this.searchTerm = value;
      // Dirty hack to send the correct value
      $("#product_search_term").val(value);

      this.submitSearch()
    }
  }
};
</script>