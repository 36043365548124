<script>
export default {
  name: 'ProductSearchItem',
  props: ['id'],
  template: '#product-search-item-template',
  data() {
    return {
      search: {}
    };
  },
  methods: {
    loadData() {
      const self = this;
      fetch('/product_searches/' + this.id + '.json?only_search=true')
        .then(response => response.json())
        .then(data => {
          self.search = data;
        });
    }
  },
  created() {
    this.loadData();
    // reload every 3 seconds if percentage < 100
    const self = this;
    setInterval(() => {
      if (self.search.percentage < 100) {
        self.loadData();
      }
    }, 2000);
  }
};
</script>