<script>
export default {
  name: 'QuotationSuggestions',
  props: ['id'],
  template: '#quotation-suggestions-template',
  data: function() {
    return {
      item: {}
    };
  },
  methods: {
    loadData: function() {
      var self = this;
      $.ajax({
        url: '/quotations/' + this.id + '/suggestions',
        success: function(data) {
          self.item = data;
        }
      });
    }
  },
  computed: {
    isLoading: function() {
      return this.item.suggestions === undefined;
    },
    suggestions: function() {
      if (this.item.suggestions === undefined) {
        return '';
      }
      return this.item.suggestions.join(', ');
    }
  },
  created: function() {
    this.loadData();
  }
};
</script>