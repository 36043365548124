<script>
export default {
  name: 'ChatRoom',
  template: '#chat-room-template',
  data() {
      return {
        chatRoom: {},
        chatMessages: [],
        newMessage: { body: '' },
        awaitingSystemResponse: false,
        currentUserId: window.currentUserId
      };
    },
    // Fetch the chat room and messages when the page loads
    mounted() {
      this.loadRoom();
    },
    watch: {
      chatMessages: {
        handler() {
          this.checkAwaitingResponse();
          this.scrollToBottom();
        },
        deep: true,
      },
    },
    methods: {
      checkAwaitingResponse() {
        if (this.chatMessages.length > 0) {
          const lastMessage = this.chatMessages[this.chatMessages.length - 1];
          this.awaitingSystemResponse = this.isCurrentUser(lastMessage.user_id);
        }
      },
      loadRoom() {
        axios.get('/chat_rooms/' + this.chatRoomId() + '.json')
        .then(response => {
          // if content has changed, then scroll to bottom
          let contentHasChanged = JSON.stringify(this.chatMessages) !== JSON.stringify(response.data.chat_messages);
          this.chatRoom = response.data;
          this.chatMessages = response.data.chat_messages;

          if(contentHasChanged) {
          //  this.scrollToBottom();
          }
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
      },
      formattedMessage(string) {
        return marked.parse(string);
      },
      chatRoomId() {
        return window.location.pathname.split('/').pop();
      },
      isCurrentUser(userId) {
        return parseInt(userId) === parseInt(this.currentUserId);
      },
      timeAgo(date) {
        return moment(date).locale('pt-br').fromNow();
      },
      scrollToBottom() {
        setTimeout(() => {
            let chatWindow = document.querySelector('.chat-messages');
            //chatWindow.scrollTop = chatWindow.scrollHeight;

            // Scroll to bottom of the chat window with smooth animation
            chatWindow.scrollTo({ top: chatWindow.scrollHeight, behavior: 'smooth' });
        }, 100);
      },
      submitMessage() {
        let data = { chat_message: JSON.parse(JSON.stringify(this.newMessage)) };
        let url = '/chat_rooms/' + this.chatRoomId() + '/chat_messages';
        this.newMessage.body = '';
        this.chatMessages.push({
          body: data.chat_message.body,
          user_id: this.currentUserId,
          created_at: new Date()
        });
        //this.scrollToBottom();
        axios.post(url, data)
          .then(response => {
            this.scrollToBottom();
            this.loadRoom();
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
      }
    }
};
</script>