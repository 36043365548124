<script>
export default {
  name: 'EmptyStateSuggestion',
  props: ['id'],
  template: '#empty-state-suggestion-template',
  data: function() {
    return {
      data: {}
    };
  },
  methods: {
    loadData: function() {
      var self = this;
      $.ajax({
        url: '/product_searches/' + this.id + '/suggestions',
        success: function(data) {
          self.data = data;
        }
      });
    }
  },
  created: function() {
    this.loadData();
  }
}
</script>